import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header2';
import Footer from '../layout/footer4';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllAbout, getBanners, getNewsAndArticles } from '../server';
import BlogSlider1 from '../element/blogSlider1';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import "../../css/test/banner.css";


var bnr3 = require('./../../images/banner/bnr3.jpg');

function Mission() {
  const [aboutUs, setAboutUs] = useState(null);
  const [news, setNews] = useState(null);
  const [banner, setBanner] = useState(null);
  useEffect(() => {
    getAllAbout({ type: 'mission_vision_values' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setAboutUs(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getBanners({ type: 'about us', subType: 'mission vision values' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getNewsAndArticles()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setNews(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false
  };

  return (
    <>
      <Header />

      {banner && (
        <div className="page-content bg-white">
          {/* <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              backgroundImage: `url(' https://hr.sweetitech.co.uk/abc/${banner.image.url}')`,
            }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Mission,Vision & Values</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li>Mission,Vision & Values </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}

          <div className="test-banner-container">
            {/* Left Text */}
            <div className="test-banner-text">Mission,Vision & Values</div>

            {/* Image Slider */}
            <div className="test-banner-slider">
              <Slider {...settings} className="h-full">
                <div>
                  <img src={require('./../../css/test/images/1.jpg')} alt="Slide 1" />
                </div>
                <div>
                  <img src={require('./../../css/test/images/2.jpg')} alt="Slide 2" />
                </div>
                <div>
                  <img src={require('./../../css/test/images/3.jpg')} alt="Slide 3" />
                </div>
                <div>
                  <img src={require('./../../css/test/images/4.jpg')} alt="Slide 4" />
                </div>
                <div>
                  <img src={require('./../../css/test/images/5.jpg')} alt="Slide 5" />
                </div>
                <div>
                  <img src={require('./../../css/test/images/6.jpg')} alt="Slide 6" />
                </div>
                <div>
                  <img src={require('./../../css/test/images/7.jpg')} alt="Slide 7" />
                </div>
                <div>
                  <img src={require('./../../css/test/images/8.jpg')} alt="Slide 8" />
                </div>
                <div>
                  <img src={require('./../../css/test/images/9.jpg')} alt="Slide 9" />
                </div>
                <div>
                  <img src={require('./../../css/test/images/10.jpg')} alt="Slide 10" />
                </div>
              </Slider>
            </div>

            {/* Right Text */}
            <div className="test-banner-text">
              <ul className="list-inline">
                <li>
                  <Link to={'/'}>Home <i class="fa fa-chevron-right" aria-hidden="true"></i></Link>
                </li>
                <li>Mission,Vision & Values </li>
              </ul>
            </div>
          </div>

          <div className="content-block">
            {aboutUs &&
              aboutUs.map((item, index) => (
                <div className="section-full content-inner bg-white">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 m-b30">
                        <div className="our-story">
                          <span>Mission,Vision & Values</span>
                          <h2 className="title">{item?.title}</h2>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${item?.body}`,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 m-b30 ">
                        <img
                          src={`https://hr.sweetitech.co.uk/abc/${item?.image?.url}`}
                          className="radius-sm"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div
              className="section-full content-inner bg-white wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">News & Articles</h2>
                  <p></p>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <BlogSlider1 data={news} imgEffect="rotate" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
export default Mission;
